import Image from "../Media/Image";
import { useEffect } from "react";
import React, { useState } from 'react';
import { CarouselContext } from 'pure-react-carousel';
import { useContext } from "react";
import './foodCarousel.less';
import {
    CarouselProvider,
    Slider,
    Slide
  } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

interface IFood{
  idF: number
  name: string,
  caracteristic: string,
  price: string,
  image: string,
  qty: number
}

interface IFoodCarousel{
  foodSelected: number,
  changeFoodSelected: Function,
  closeModal: Function,
  getActualCart: Function,
  foodList: Array<IFood>
}

const FoodCarousel = (props : IFoodCarousel) => {
    const { foodList, foodSelected, changeFoodSelected, closeModal, getActualCart } = props; 
    const [currentSlide, setCurrentSlide] = useState(0);
    const [firstTime, setFirstTime] = useState(true);

    const items = foodList.map((item, index : number) => {
        return item.image != "" && <Slide index={index} key={index}>
            <Image className="slideCardContainer" width="120px" height="120px" source={item.image} alt={"text"} />
        </Slide>
    });

    function MyComponentUsingContext() : any {
        const carouselContext = useContext(CarouselContext);
        React.useEffect(() => {
          function onChange() {
            setCurrentSlide(carouselContext.state.currentSlide);
          }
          carouselContext.subscribe(onChange);
          return () => carouselContext.unsubscribe(onChange);
        }, [carouselContext]);
    }

    React.useEffect(() => {
      if(!firstTime){
        updateFoodData(currentSlide);
        getActualCart(); 
      }
      setFirstTime(false);
    }, [currentSlide]);

    const updateFoodData = (number : number) => {
      changeFoodSelected(number);
    }

    /*
    <div onClick={() => closeModal()}>
        <Image width="30px" alt="img-back" source={"decoration/back.svg"} className="backIcon" />
      </div>
    */
   
    return <React.Fragment>
      
      {foodList.length-1 != -1 &&
          <CarouselProvider 
          naturalSlideWidth={80}
          naturalSlideHeight={100}
          totalSlides={foodList.length}
          visibleSlides={1}
          currentSlide={foodSelected}
          >
            <Slider>
              {items}
            </Slider>
            <MyComponentUsingContext />
          </CarouselProvider>
      }
    </React.Fragment>

}

export default FoodCarousel;