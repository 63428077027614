import {combineReducers} from 'redux';
import * as actionTypes from './actionTypes';


const initialStateFood = {
    idF: null,
    name: null,
    price: null,
    image: null,
    caracteristic: null,
    type: null
}

const initialState = {
    foods: [],
    food: initialStateFood
}

const allFood = (state = initialState.foods, action : any) => {
    switch (action.type) {
        case actionTypes.GET_FOOD:
            return action.foods;
        default:
            return state;
    }
}

const reducer = combineReducers({
    allFood
});

export default reducer;