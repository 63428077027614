import * as foodService from '../../api/services/foodService';
import * as actionTypes from './actionTypes';


const getFoodCompleted = (food : any) => ({
    type: actionTypes.GET_FOOD,
    foods: food,
  });

export const getFood = (keypass : string, onSuccess : Function) => (dispatch : any) => {
    foodService.getFood(keypass, (response : any) => {
      if(response.length % 2 != 0){response.push({idF: null, name: null, price: null, image: "", caracteristic: null, type: null})};
      dispatch(getFoodCompleted(response));
      onSuccess(response);
    });
};

/*export const getFood = (keypass : string, order : string, onSuccess : Function) => (dispatch : any) => {
    foodService.getFood(keypass, order, (response : any) => {
      //response.splice(1, 0, {idF: null, name: null, price: null, image: "", caracteristic: null, type: null});
      if(response.length % 2 != 0){response.push({idF: null, name: null, price: null, image: "", caracteristic: null, type: null})}
      dispatch(getFoodCompleted(response));
      onSuccess(response);
    });
};*/