import { Slide } from 'react-slideshow-image';
import HomeSliderControls from './HomeSliderControls';
import "./tutorialSlideShow.less";
import 'react-slideshow-image/dist/styles.css';
import { useIntl } from 'react-intl';
import Row from '../layouts/Row';
import React, { useRef, useState } from 'react';

const TutorialSlideShow = () => {
    const intl = useIntl();
    const listInnerRef = useRef<HTMLInputElement>(null)

    const [activeIndex, setActiveIndex] = useState(0);

    const images = [
        "https://demo.exylon.app/restaurante/assets/img/slider/1.jpg",
        "https://demo.exylon.app/restaurante/assets/img/slider/2.jpg",
        "https://demo.exylon.app/restaurante/assets/img/slider/3.jpg",
    ];

    /*const proprietes = {
        duration: 100000,
        transitionDuration: 300,
        infinite: false,
        indicators: true,
        arrows: false
    }

    const onScroll = () => {
        if (listInnerRef.current) {
          const { scrollLeft } = listInnerRef.current;
          console.log(scrollLeft);
        }
    };*/

    function scrollHandler(e : any) {
        const position = e.target.scrollLeft / e.target.offsetWidth;
        setActiveIndex(Math.round(position))
    }

    const changeIndex = () => {
        setActiveIndex(Math.round(1));
    }

    //<div className='tutorialButtonContainer'><a onClick={() => setTutorial("tuto")}>{intl.formatMessage({id: 'project.tutorial.start.button'})}</a></div>

    return <>
        <Row className="homeSlider">
            <div onScroll = {scrollHandler}>
                <div className="homeSlide">
                    <img src={images[0]}/>
                    <p className='tutorialSlideText1'>{intl.formatMessage({id: 'project.tutorial.title'})}</p>
                    <p className='tutorialSlideText2'>{intl.formatMessage({id: 'project.tutorial.title2'})}</p>
                </div>
                <div className="homeSlide">
                    <img src={images[1]}/>
                    <p className='tutorialSlideText1'>{intl.formatMessage({id: 'project.tutorial.title'})}</p>
                    <p className='tutorialSlideText2'>{intl.formatMessage({id: 'project.tutorial.title2'})}</p>
                </div>
                <div className="homeSlide">
                    <img src={images[2]}/>
                    <p className='tutorialSlideText1'>{intl.formatMessage({id: 'project.tutorial.title'})}</p>
                    <p className='tutorialSlideText2'>{intl.formatMessage({id: 'project.tutorial.title2'})}</p>
                </div>
            </div>
        </Row>
        <Row className={'homeSliderControlsContainer'}>
            <HomeSliderControls activeIndex = {activeIndex} changeIndex = {changeIndex} images={images} />
        </Row>
    </>

    /*
    <Slide {...proprietes} >
                <div className="each-slide-effect" onScroll={() => onScroll()} ref={listInnerRef}>
                    <div className="each-slide-effect-content-container" style={{ 'backgroundImage': `url(${images[0]})` }} />
                    <p className='tutorialSlideText1'>{intl.formatMessage({id: 'project.tutorial.title'})}</p>
                    <p className='tutorialSlideText2'>{intl.formatMessage({id: 'project.tutorial.title2'})}</p>
                </div>
                <div className="each-slide-effect">
                    <div className="each-slide-effect-content-container" style={{ 'backgroundImage': `url(${images[1]})` }} />
                    <p className='tutorialSlideText1'>{intl.formatMessage({id: 'project.tutorial.title'})}</p>
                    <p className='tutorialSlideText2'>{intl.formatMessage({id: 'project.tutorial.title2'})}</p>
                </div>
                <div className="each-slide-effectButton">
                    <div className="each-slide-effect-content-container" style={{ 'backgroundImage': `url(${images[2]})` }} />
                    <p className='tutorialSlideText1'>{intl.formatMessage({id: 'project.tutorial.title'})}</p>
                    <p className='tutorialSlideText2'>{intl.formatMessage({id: 'project.tutorial.title2'})}</p>
                    <div className='tutorialButtonContainer'><a onClick={() => setTutorial("tuto")}>{intl.formatMessage({id: 'project.tutorial.start.button'})}</a></div>
                </div>
            </Slide>
    */
}

export default TutorialSlideShow;
