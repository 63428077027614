export default {
    'project.common.ErrorDialog.title': 'Error',
    'project.global.exceptions.NetworkError': 'Network error',
    'project.global.buttons.close': 'Close',

    'project.global.title.prefix': 'Recipes |',

    'project.tutorial.title': 'A Meixor',
  'project.tutorial.title2': 'App De Comidas.',
    'project.tutorial.start.button': 'Comenzar',

    'project.home.foodslider.title': 'Categori',
    'project.home.foodslider.text0': 'Entrantes',
    'project.home.foodslider.text1': 'Arroces',
    'project.home.foodslider.text2': 'Carnes',
    'project.home.foodslider.text3': 'Pescados',
    'project.home.foodslist.title': 'Popular',
    'project.header.cart.foodList.title': 'Pedido',
    'project.header.cart.foodList.remove': 'Eliminar todo',
    'project.header.cart.foodList.food.price': 'Precio: ',
    'project.header.cart.foodList.totalPrice': 'Total',
    'project.header.cart.foodList.button': 'Enviar pedido a cocina',
    'project.header.cart.more.title': 'Agrega a tu pedido',

    'project.payment.title': 'Cancelar',
    'project.payment.checkbox.saveInfo': 'Guardar este pago para el futuro.',

    'project.wait.button.text': 'Volver o inicio'
  };
