import {combineReducers} from 'redux';
import * as actionTypes from './actionTypes';

const initialStateCategory = {
    idC: null,
    image: null,
    title: null
}

const initialState = {
    categories: [],
    category: initialStateCategory
}

const allCategories = (state = initialState.categories, action : any) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORIES:
            return action.categories;
        default:
            return state;
    }
}

const reducer = combineReducers({
    allCategories
});

export default reducer;