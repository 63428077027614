import { useState } from "react";

import "./tagModal.less";

interface ITagModal{
  sendSMS: Function
}

const TagModal = (props : ITagModal) => {
  const { sendSMS } = props;

  const [phone, setPhone] = useState<string>("");

  return <div className="tag-modal">
    <img src="https://demo.exylon.app/restaurante/assets/img/nfc/send.png" />
    <h1>Verifica tu teléfono</h1>
    <p>Por favor, introduce tu número de teléfono.</p>
    <div className="tag-modal-button">
      <input name="phone" value={phone} placeholder="123456789" onChange={(e) => setPhone(e.target.value)} />
      <button onClick={() => sendSMS(phone)}>Enviar</button>
    </div>
  </div>
}

export default TagModal;
