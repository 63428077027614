import Image from "../../components/Media/Image";
import React from "react";
import "./allergensDetails.less";

interface IFood {
    gluten: string,
    egg: string,
    peanut: string,
    nut: string,
    mollusc: string,
    crustacean: string,
    dairy: string,
    sesame: string,
    lupin: string
}

interface IAllergenDetails{
    food: IFood
}

const AllergensDetails = (props : IAllergenDetails) => {
    const {food} = props;

    const showImage = (value : string | number) => {
        return value == 1 ? true : false;
    }

    return <div className="allergensFlexContainer">
        {showImage(food.gluten) && <Image alt="alergen-image" source={"allergens/gluten.svg"}/>}
        {showImage(food.egg) && <Image alt="alergen-image" source={"allergens/egg.svg"}/>}
        {showImage(food.peanut) && <Image alt="alergen-image" source={"allergens/peanut.svg"}/>}
        {showImage(food.nut) && <Image alt="alergen-image" source={"allergens/nut.svg"}/>}
        {showImage(food.mollusc) &&  <Image alt="alergen-image" source={"allergens/mollusc.svg"}/>}
        {showImage(food.crustacean) && <Image alt="alergen-image" source={"allergens/crustacean.svg"}/>}
        {showImage(food.dairy) && <Image alt="alergen-image" source={"allergens/dairy.svg"}/>}
        {showImage(food.sesame) && <Image alt="alergen-image" source={"allergens/sesame.svg"}/>}
        {showImage(food.lupin) && <Image alt="alergen-image" source={"allergens/lupin.svg"}/>}
    </div>
}

export default AllergensDetails;