import React, {lazy} from 'react';
import {useIntl} from 'react-intl';
import Paragraph from '../../components/Texts/Paragraph';
import Image from '../../components/Media/Image';

import CategoryCard from '../../components/Cards/CategoryCard';
import Row from '../../layouts/Row';
import Column from '../../layouts/Column';
import CardList from '../../components/Lists/CardList';

interface IFoodList {
    actualType: string,
    updateList: Function,
    foodList: any,
    activeModal: Function
    displayPromotionList: boolean
    currentCategoryName: string
    changePromotionList: Function
    resetCategoryName: Function
    resetPopularList: Function
}

const FoodList = (props : IFoodList) => {
    const intl = useIntl();
    const { resetPopularList, changePromotionList, actualType, updateList, foodList, activeModal, displayPromotionList, currentCategoryName, resetCategoryName } = props;

    const executeActions = () => {
        changePromotionList(true);
        resetCategoryName();
        resetPopularList();
    }
    return <Row>
        <Column className="flexCategoryCard">
            <Paragraph className="bold">{currentCategoryName === "" ? intl.formatMessage({id: 'project.home.foodslist.title'}) : currentCategoryName}</Paragraph>
            {!displayPromotionList && <div onClick={() => executeActions()}><Image classNameContainer='flexCategoryCardImageContainer' source="decoration/arrow.svg"/></div>}
            {displayPromotionList && <CategoryCard actualType={actualType} updateList={updateList} />}
        </Column>
        <Column>
            <CardList activeModal={activeModal} foodList={foodList} displayPromotionList={displayPromotionList} />
        </Column>
    </Row>
}

export default FoodList;
