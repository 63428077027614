import { Configuration, CreateChatCompletionResponseChoicesInner, OpenAIApi } from "openai";
import { IOpenaiChoice } from "./openai.interface";
import { notFoundResponse, questionAnswering, restaurantMenu } from "./exylon.model";

const mapResponseApiResponse = (choices: CreateChatCompletionResponseChoicesInner[]): IOpenaiChoice[] => {
    const res: IOpenaiChoice[] = [];
    choices.forEach(c => { if (c.message) res.push(c.message as IOpenaiChoice); });
    return res;
}

export const getCall = async (userInput: IOpenaiChoice[]): Promise<IOpenaiChoice[]> => {
    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });

    console.log('userinput', userInput);

    const openai = new OpenAIApi(configuration);
    try {
        const res = await openai.createChatCompletion({
            model: 'gpt-3.5-turbo',
            messages: [
                { role: "system", content: `Responde a lo siguiente como si fueras un asistente.\n${questionAnswering}\n${restaurantMenu}\n${notFoundResponse}` },
                ...userInput
            ],
            temperature: 0.9,
            max_tokens: 150,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            n: 1,
            stop: ['\nUser:']
        });
        console.log('res', mapResponseApiResponse(res.data.choices))
        return mapResponseApiResponse(res.data.choices);
    } catch (error) {
        console.error('ERRRRRROR', error);
        return [];
    }
}
