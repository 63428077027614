import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import Image from "../Media/Image";
import Paragraph from "../Texts/Paragraph";

import * as cartActions from "../../../modules/cart/actions";

import "./cartList.less";

interface IFood{
    idF: number
    name: string,
    description: string
    caracteristic: string,
    price: string,
    image: string,
    qty: number
}

interface ICartList{
    food: IFood
    cart: any
    updateCart: Function
    border: string
    setRecalculateCard: Function
    recalculateCard: number
}
const CartList = (props : ICartList) => {
    const {food, cart, updateCart, border, setRecalculateCard, recalculateCard} = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const removeProduct = () => {
        dispatch(cartActions.removeProduct(food.name, cart, (response : any) => {
            updateCart(response);
        }));
    }

    const printExtras = () => {
        const currentFood = cart.filter((c: { name: string; }) => c.name === food.name)[0];
        if(currentFood){
            const extras: Array<string> = currentFood.extras.concat(currentFood.method);
            return extras;
        }
        return [];
    }

    const addProduct = () => {
        dispatch(cartActions.insertProduct(cart, food.name, food.description, food.price, food.image, {extras: [], method: []}, (response: any) => {
            updateCart(response);
            setRecalculateCard(recalculateCard+1);
        }));
    }

    return <section>
        <section className={"cartListContainer "+(border)}>
            <div className="cartListContainerContent">
                <div className="cartListContainerContentImageCard">
                    <Image alt="food-cart-banner" source={food.image}></Image>
                    <div>
                        <Paragraph className="blackNotBold">{food.name}</Paragraph>
                        <div className="cartListContainerContentImageCardQty">
                            <div onClick={() => removeProduct()}><Image alt="less-button" className="lessButtonFoodCard" width="20px" source={"decoration/less.svg"}></Image></div>
                            <span>{food.qty}</span>
                            <div onClick={() => addProduct()}><Image alt="plus-button" className="plusButtonFoodCard"  width="20px" source={"decoration/plus-black.svg"}></Image></div>
                        </div>
                    </div>
                </div>
                <div className="cartListContainerContentPriceCard">
                    <div onClick={() => removeProduct()}><img src="https://demo.exylon.app/restaurante/assets/img/decoration/remove-circle.svg" /></div>
                    <Paragraph>{food.price}€</Paragraph>
                </div>
            </div>
            {printExtras().length > 0 && <div className="cartListContainerExtras">
                <div className="cartListContainerExtrasList">
                    <Image alt="food-cart-banner" source="decoration/extras.png"></Image>
                    <div>
                        {printExtras().map((extra, index) => (
                            <span key={extra+index}>{extra+(index !== printExtras().length-1 ? ", " : "")}</span>
                        ))}
                    </div>
                </div>
                <div>

                </div>
            </div>}
        </section>
    </section>
}

export default CartList;
