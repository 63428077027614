const apiCall = "https://demo.exylon.app/restaurante/";

export const config = (method : {}, body : any) => {
  const config : any = {};
  config.method = method;
  if(body) {
    if(body instanceof FormData || body instanceof URLSearchParams) {
      config.body = body;
    }else {
      config.headers = {
        Accept: 'application/json'
      };
      config.body = JSON.stringify(body);
    }
  }
  return config;
};

export const configAuth = (method: string, body?: URLSearchParams | FormData | string): RequestInit => {

  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const config: RequestInit = {
    method,
    credentials: 'include',
    headers,
  };

  if(body){
    config.body = body;
  }

  return config;
};

export const appFetch = (path : string, options : any, onSuccess : Function) => {
  fetch(`${apiCall}${path}`, options)
    .then((response) => {
        try {
          response.json().then((payload) => onSuccess(payload));
        }catch (error) {
          console.log(error);
        }
    }).catch((error) => {
      console.log(error);
  });
};

export const appFetchAuth = (path : string, options : any, onSuccess : Function) => {
  fetch(`${path}`, options)
    .then((response) => {
        try {
          response.json().then((payload) => onSuccess(payload));
        }catch (error) {
          console.log(error);
        }
    }).catch((error) => {
      console.log(error);
  });
};
