import React, {lazy, useState} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import FoodPopup from '../blocks/home/FoodPopup';
import ExylonIcon from '../components/exylon/ExylonIcon';

import Header from '../../modules/app/components/Header';
import PrincipalSlider from '../blocks/home/PrincipalSlider';
import FoodSlider from '../blocks/home/FoodSlider';

interface IHome{
  currentSecretKey: string,
  socket: string,
  number: number,
  updateHeaderContainer: number,
  widthScreen: number,
}

const Home = (props: IHome) => {
  const {currentSecretKey, socket, number, updateHeaderContainer} = props;
  const [displayPromotionList, setDisplayPromotionList] = useState(true);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [ updateHeaderContainer2, setUpdateHeaderContainer2] = useState(0);

  const {state} = useLocation();

  const changePromotionList = (value : boolean) => {
    setDisplayPromotionList(value);
  }

  const changeHeaderCart = () => {
    setUpdateHeaderContainer2(updateHeaderContainer2+1);
  };

  React.useEffect(() => {
    if(state != null){
        const { popup } = state;
        setDisplayPopup(popup);
    }
  }, []);

  const cleanState = () => {
    window.history.replaceState({}, "");
    setDisplayPopup(false);
  }

  return <React.Fragment>
    <Header socket={socket} number={number} updateHeaderContainer={updateHeaderContainer2}/>
    {displayPromotionList && <PrincipalSlider />}
    <FoodSlider changeHeaderCart={changeHeaderCart} keypass={currentSecretKey} changePromotionList={changePromotionList} displayPromotionList={displayPromotionList} />
    {displayPopup && <FoodPopup cleanState={cleanState} />}
    <ExylonIcon />
  </React.Fragment>

}

Home.propTypes = {
  widthScreen: PropTypes.number.isRequired
}

export default Home;
