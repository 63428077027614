import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {IntlProvider} from 'react-intl';

import configureStore from './store';
import {App} from './modules/app';
import {initReactIntl} from './i18n';

const store = configureStore();

const {locale, messages} = initReactIntl();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <IntlProvider locale={locale} messages={messages}>
      <App />
    </IntlProvider>
  </Provider>
);
