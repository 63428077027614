import { configAuth, appFetchAuth } from '../appFetch';

export const decodeNFC = (picc: string, cmac: string, onSuccess : Function) => {
  appFetchAuth(`https://38.242.246.233:5000/api/tag?picc_data=${picc}&cmac=${cmac}`, configAuth('GET', ""), onSuccess);
}

export const auth = (code: string, phone : string, restaurant : string, table : string, onSuccess : Function) => {
  let formData = new URLSearchParams();
  formData.append('code', code);
  formData.append('phone', phone);
  formData.append('restaurant', restaurant);
  formData.append('table', table);
  appFetchAuth(`https://api.exylon.app/api/auth/auth`, configAuth('POST', formData), onSuccess);
}

export const checkIfAuth = (onSuccess : Function) => {
  appFetchAuth(`https://api.exylon.app/api/auth/checkIfAuth`, configAuth('GET', ""), onSuccess);
}

export const checkSession = (picc: string, cmac: string, onSuccess : Function) => {
  let formData = new URLSearchParams();
  formData.append('picc', picc);
  formData.append('cmac', cmac);
  appFetchAuth(`https://api.exylon.app/api/auth/checkSession`, configAuth('POST', formData), onSuccess);
}

export const sms = (phone: string, onSuccess : Function) => {
  let formData = new URLSearchParams();
  formData.append('phone', phone);
  appFetchAuth(`https://api.exylon.app/api/auth/sms`, configAuth('POST', formData), onSuccess);
}
