import { useState } from "react";
import { useDispatch } from "react-redux";
import { allergensMockList } from "./allergens.mock";

import Image from "../../components/Media/Image";

import * as cartActions from '../../../modules/cart/actions';

import "./extrasModal.less";

const ExtrasModal = (props: any) => {
  const { content, cart, food, updateCartContainer, setUpdateCartContainer, setShowExtrasModal } = props;

  const [extrasToAdd, setExtrasToAdd] = useState({extras: [], method: []});
  const dispatch = useDispatch();

  const addProduct = () => {
    dispatch(cartActions.insertProduct(cart, food.name, food.description, food.price, food.image, extrasToAdd, () => {
      setUpdateCartContainer(updateCartContainer+1);
      setShowExtrasModal({status: false, value: {extras: "", method: ""}});
    }));
  }

  const handleExtraChange = (extra : string) => {
    if(extrasToAdd.extras.filter(e => e === extra)[0]){
      let newList = extrasToAdd.extras.filter(item => item !== extra);
      setExtrasToAdd((prev : any) => ({ ...prev, "extras": newList }));
    }else{
      setExtrasToAdd((prevState : any) => ({
        ...prevState,
        extras: [...prevState.extras, extra]
      }));
    }
  }

  const handleMethodChange = (extra : string) => {
    if(extrasToAdd.method.length >= 1 && extrasToAdd.method[0] === extra){
      setExtrasToAdd((prev : any) => ({ ...prev, "method": [] }));
    }else{
      setExtrasToAdd((prevState : any) => ({
        ...prevState,
        method: [...prevState.method, extra]
      }));
    }
  }

  const getAllergenImages = (allergen : string) => {
    return allergensMockList[allergen].map((a: string) => (
      <Image key={a} alt="alergen-image" source={a}/>
    ));
  }

  const getFoodExtras = (extras: any, method?: boolean) => {

    const extrasList : Array<string> = extras.split(';');

    return extrasList.map(extra => (
      <div key={extra} className="extrasModalCardFlex">
        <div className="extrasModalCardFlexFirst">
          <p>0.5€</p>
          <p>{extra}</p>
        </div>
        <div className="extrasModalCardFlexSecond">
          <div>
            {!method && getAllergenImages(extra)}
          </div>
          <div>
            {!method ? <input type="checkbox" onChange={() => handleExtraChange(extra)} /> : <div className={"extrasModalCardFlexMultiSelector"+(extrasToAdd.method.length >= 1 && extrasToAdd.method[0] === extra ? " --on" : " --off")} onClick={() => handleMethodChange(extra)} />}
          </div>
        </div>
      </div>
    ))
  }

  return <div className="extrasModalLayout">
    <div className="extrasModal">
        {content.extras != "" &&
          <div className="extrasModalCard">
            {getFoodExtras(content.extras)}
          </div>
        }
        {content.method != "" &&
          <div className="extrasModalCard">
            {getFoodExtras(content.method, true)}
          </div>
        }
        <span className="extrasModalTitle">EXTRAS</span>
        <div className="extrasModalButton">
          <div onClick={() => addProduct()}><img alt="add-image" src={"https://demo.exylon.app/restaurante/assets/img/allergens/add.svg"}/></div>
          <div onClick={() => setShowExtrasModal({status: false, value: {extras: "", method: ""}})}><img alt="remove-image" src={"https://demo.exylon.app/restaurante/assets/img/allergens/remove.svg"}/></div>
        </div>
    </div>
  </div>
}

export default ExtrasModal;
