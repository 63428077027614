import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

interface OTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onChangeOTP }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value;
    if (!/^[0-9]$/.test(value) && value !== '') return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }

    onChangeOTP(newOtp.join(''));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: "center" }}>
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          inputMode="numeric"
          value={digit}
          maxLength={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target, index)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
          style={{
            width: '40px',
            textAlign: 'center',
            fontSize: '20px',
            border: 'none',
            borderBottom: `2px solid ${digit ? '#53e38e' : 'gray'}`,
            outline: 'none',
            backgroundColor: 'transparent',
            color: '#000',
          }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
