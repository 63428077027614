import { combineReducers } from 'redux';

import app from '../modules/app';
import foods from '../modules/foods';
import categories from '../modules/categories';
import types from '../modules/types';
import cart from '../modules/cart';

const reducer = combineReducers({
  app: app.reducer,
  foods: foods.reducer,
  categories: categories.reducer,
  types: types.reducer,
  cart: cart.reducer
});

export default reducer;
