import { config, appFetch } from '../appFetch';

/*export const getFood = (keypass : string, order : string, onSuccess : Function) => {
    let formData = new FormData();
    formData.append('keypass', keypass);
    formData.append('number', order);
    appFetch(`getFood.php`, config('POST', formData), onSuccess);
}*/

export const getFood = (keypass : string, onSuccess : Function) => {
    let formData = new FormData();
    formData.append('keypass', keypass);
    appFetch(`getFood.php`, config('POST', formData), onSuccess);
}

export const insertOrder = (menus : any, revenue : string, content : any, idR : any, onSuccess : Function) => {
    let formData = new FormData();
    formData.append('menus', menus);
    formData.append('revenue', revenue);
    formData.append('content', content);
    formData.append('idR', idR);
    appFetch(`insertOrder.php`, config('POST', formData), onSuccess);
}
  