import PropTypes from 'prop-types';

import './paragraph.less';

interface IParagraph{
  padding?: string,
  children: any,
  className?: string
}

const Paragraph = (props : IParagraph) => {

  const {padding, children, className} = props;

  return <p className={`paragraph ${className} ${padding=="none" ? 'nopadding' : ''}`}>{children}</p>
}

Paragraph.propTypes = {
  padding: PropTypes.string
};

export default Paragraph;
