import React, { useState } from "react";
import Paragraph from "../Texts/Paragraph";
import './cardList.less';
import Image from "../Media/Image";

interface IFood{
    idF: number
    name: string,
    caracteristic: string,
    price: string,
    image: string
}

interface ICardList{
    foodList: Array<IFood>
    activeModal: Function
    displayPromotionList: Boolean
}

const CardList = (props : ICardList) => {
    const { foodList, activeModal, displayPromotionList } = props;

    const getColor = (caracteristic : string) => {
        switch(true){
            case caracteristic === "energy booster":
                return "purple";
            case caracteristic === "protein":
                return "red";
            case caracteristic === "carbohydrates":
                return "yellow";
            default:
                return "green";
        }
    }

    const items = foodList.map((item, index) => {

        return item.idF != null ?
            <div className={ index % 2 == 0 ? "foodCardContainer" : displayPromotionList ? "foodCardContainerMoved" : "foodCardContainer"} key={index}>
                <div className="imageCardContainer">
                        <div>
                            <div onClick={() => activeModal(index)}><Image width="100px" height="100px" source={item.image} alt={"text"}/></div>
                        </div>
                        <div>
                            <Paragraph className={"caracteristicText "+getColor(item.caracteristic)}>{item.caracteristic}</Paragraph>
                        </div>
                </div>
                <Paragraph className="foodTitleName">{item.name}</Paragraph>
                <div className="foodDetailsContainer">
                    <div className="buttonCardContainer">
                        <Paragraph className="priceCardText">{item.price+"€"}</Paragraph>
                        <div className="buttonCardContainer">
                            <div onClick={() => activeModal(index)}>
                                <Image classNameContainer="plusFoodListContainer" width="20px" height="20px" alt="plus-food-list" source="decoration/shopping.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div key={index} />
    });

    return <div className="cardContainer">
        {items}
    </div>
}

export default CardList;
