import * as actionTypes from './actionTypes';
import * as foodService from '../../api/services/foodService';

const insertProductCompleted = (product : any) => ({
    type: actionTypes.GET_CART,
    cart: product,
});

export const removeAll : any = (onSuccess : Function) => (dispatch : any) => {
  dispatch(insertProductCompleted([]));
  onSuccess([]);
};

export const insertProduct : any = (currentCart : any, name : string, description : string, price : string, image : string, extrasToAdd : any, onSuccess : Function) => (dispatch : any) => {
    currentCart.push({name: name, description: description, price: price, image: image, extras: extrasToAdd.extras, method: extrasToAdd.method});
    dispatch(insertProductCompleted(currentCart));
    onSuccess(currentCart);
};

export const removeProduct : any = (name : string, cart : any, onSuccess : Function) => (dispatch : any) => {
  let cartResult : any = [];
  let deleted = false;

  cart.map((product: { name: string; }) => {
      switch(true) {
        case product.name === name && deleted:
            cartResult.push(product);
            break;
        case product.name != name:
            cartResult.push(product);
            break;
        default:
            deleted = true;
            break;
      }
  });

  dispatch(insertProductCompleted(cartResult));
  onSuccess(cartResult);
};

export const removeAllCart : any = (onSuccess : Function) => (dispatch : any) => {
    dispatch(insertProductCompleted([]));
    onSuccess();
}

export const insertOrder = (menus : number, revenue : string, content : any, idR : number, onSuccess : Function) => (dispatch : any) => {
    foodService.insertOrder(menus, revenue, content, idR, (response : any) => {
      onSuccess(response);
    });
};
