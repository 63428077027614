import * as typeService from '../../api/services/typeService';
import * as actionTypes from './actionTypes';


const getTypeCompleted = (type : any) => ({
    type: actionTypes.GET_TYPES,
    types: type,
  });


export const getTypes = (keypass : string, onSuccess : Function) => (dispatch : any) => {
    typeService.getTypes(keypass, (response : any) => {
      dispatch(getTypeCompleted(response));
      onSuccess(response);
    });
};