import React, {lazy, useState} from 'react';
import {useIntl} from 'react-intl';

import * as foodActions from '../../../modules/foods/actions';
import * as typeActions from '../../../modules/types/actions';
import * as categoryActions from "../../../modules/categories/actions";
import {useDispatch} from 'react-redux';

import PromotionsList from '../../components/Lists/PromotionsList';
import Row from '../../layouts/Row';
import Column from '../../layouts/Column';
import Paragraph from '../../components/Texts/Paragraph';
import FoodList from './FoodList';
import FoodDetails from "./FoodDetails";

interface IFood{
    idF: number
    name: string,
    caracteristic: string,
    price: string,
    image: string,
    gluten: string,
    egg: string,
    peanut: string,
    nut: string,
    mollusc: string,
    crustacean: string,
    dairy: string,
    sesame: string,
    lupin: string,
    description: string,
    qty: number
    popular: number
}

interface IFoodSlider {
    keypass: string
    changeHeaderCart: Function
    changePromotionList: Function
    displayPromotionList: boolean
}

const FoodSlider = (props : IFoodSlider) => {
    const intl = useIntl();
    const dispatch : any = useDispatch();
    const { keypass, changeHeaderCart, changePromotionList, displayPromotionList } = props;

    const [actualType, setActualType] = useState("Ver todos");
    const [currentCategoryName, setCurrentCategoryName] = useState("");
    const [firstTime, setFirstTime] = useState(true);
    const [currentListOrder, setCurrentListOrder] = useState(0);
    const [fullFoodList, setFullFoodList] : any = useState([]);
    const [auxiliarPopularList, setAuxiliarPopularList] : any = useState([]);
    const [foodList, setFoodList] = useState([]);
    const [typesNumber, setTypesNumber] : any = useState([]);

    const [foodSelected, setFoodSelected] = useState(0);
    const [modal, setModal] = useState(false);
    const [currentCategoryList, setCurrentCategoryList] : any = useState([]);
    const [classType, setClassType] = useState("SliderPointsFirst");
    const classList = ["SliderPointsFirst","SliderPointSecond","SliderPointsThird"];
    const list = [0 , 1 , 2];
    const points = list.map((item, index) => <div key={index}></div>);

    const changePoints = (number : number) => {
        setClassType(classList[number]);
    }

    const activeModal = (number : number) => {
        setFoodSelected(number);
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }

    const changeFoodSelected = (number : number) => {
        setFoodSelected(number);
    }

    const resetCategoryName = () => {
        setCurrentCategoryName("");
    }

    const resetPopularList = () => {
        setFoodList(auxiliarPopularList);
    }

    React.useEffect(() => {
        dispatch(categoryActions.getCategories(keypass, (categoryListResponse : any) => {
            setCurrentCategoryList(categoryListResponse);
            dispatch(foodActions.getFood(keypass, (response : any) => {
                setFullFoodList(response);
                const filteredPopularList = response.filter((food: { popular: number; }) => food.popular == 1);
                if(filteredPopularList.length % 2 != 0){filteredPopularList.push({idF: null, name: null, price: null, image: "", caracteristic: null, type: null})};
                setAuxiliarPopularList(filteredPopularList);
                setFoodList(filteredPopularList);
            }));
        }));
    }, []);

    /*React.useEffect(() => {
        if(firstTime){
            dispatch(typeActions.getTypes(keypass, (response : any) => {
                setTypesNumber(response);
                setFirstTime(false);
                dispatch(foodActions.getFood(keypass, currentListOrder.toString(), (response : any) => {
                    setFullFoodList(response);
                    setFoodList(response);
                }));
            }));
        }else{
            dispatch(foodActions.getFood(keypass, currentListOrder.toString(), (response : any) => {
                setFoodList(response);
            }));
        }
    }, [currentListOrder]);
    */

    const updateList = () => {
        changePromotionList(false);
        filterListByCategory(currentCategoryList[0].idC, currentCategoryList[0].title);
        //setFirstTime(true);
        //if(currentListOrder < (typesNumber.length)-1){setCurrentListOrder(currentListOrder+1); setActualType(typesNumber[currentListOrder+1].type);}
    }

    const filterListByCategory = (categoryNumber : number, categoryName : string) => {
        changePromotionList(false);
        const filteredList = fullFoodList.filter((food: { idC: number; }) => food.idC == categoryNumber);
        setCurrentCategoryName(categoryName);
        if(filteredList.length % 2 != 0){filteredList.push({idF: null, name: null, price: null, image: "", caracteristic: null, type: null})};
        setFoodList(filteredList);
    }

    return <Row className='footer-margin'>
        {displayPromotionList && <Paragraph className="bold">{intl.formatMessage({id: 'project.home.foodslider.title'})}</Paragraph>}
        <PromotionsList changePoints={changePoints} keypass={keypass} list={["green","purple","red","blue"]} filterListByCategory={filterListByCategory} promotions={false}/>
        <FoodList resetPopularList={resetPopularList} resetCategoryName={resetCategoryName} changePromotionList={changePromotionList} activeModal={activeModal} actualType={actualType} updateList={updateList} foodList={foodList} displayPromotionList={displayPromotionList} currentCategoryName={currentCategoryName} />
        {modal && <FoodDetails changeHeaderCart={changeHeaderCart} closeModal={closeModal} changeFoodSelected={changeFoodSelected} foodList={foodList} foodSelected={foodSelected}/>}
    </Row>
}

export default FoodSlider;
