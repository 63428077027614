import React from 'react';

import './column.less';

interface IColumn {
  children: any,
  className?: string
}

const Column = (props : IColumn) => {
  const {children, className} = props;
  
  return <div className={`column ${className}`}>{children}</div>;
}
  
Column.defaultProps = {
  className: ""
};

export default Column;
