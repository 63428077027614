import Row from "../layouts/Row";
import React from "react";
import Image from "../components/Media/Image";
import "./waitScreen.less";
import { Link } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css';
import { useIntl } from 'react-intl';

const WaitScreen = () => {
    const intl = useIntl();

    return <Row className="laguageSelectorPrincipalRow">
        <div className="laguageSelectorBannerContainer">
            <Image source="decoration/waitScreen.jpg"/>
            <Link to="/" className="waitScreenStartButton">{intl.formatMessage({id: 'project.wait.button.text'})}</Link>
        </div>
    </Row>
}

export default WaitScreen;