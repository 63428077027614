import Paragraph from "../../components/Texts/Paragraph";
import "./foodPopup.less";

interface IFoodPopup{
    cleanState: Function
}

const FoodPopup = (props: IFoodPopup) => {
    const {cleanState} = props;

    return <div className="waiting-food-popup-layout">
        <div className="waiting-food-popup-container">
            <div className='waiting-food-popup'>
                <div className="waiting-food-content-popup-container">
                    <img onClick={() => cleanState()} className="waiting-food-popup-img" src="https://demo.exylon.app/restaurante/assets/img/slider/x.png" />
                    <Paragraph className="waiting-food-popup-title">PREPARANDO...</Paragraph>
                    <Paragraph className="waiting-food-popup-text">TE RELAMERÁS LOS DEDOS EN UNOS <strong>12 MINUTOS</strong></Paragraph>
                </div>
            </div>
        </div>
    </div>
}

export default FoodPopup;
