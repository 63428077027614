import React from 'react';
import PropTypes from 'prop-types';

import './row.less';

interface IRow {
  children: any,
  childRef?: any,
  fullscreen?: boolean,
  className?: string
}

const Row = (props : IRow) => {
  const { children, childRef, fullscreen, className } = props;

  return <section
      ref={childRef}
      className={`row 
        ${className}
        ${fullscreen ? "full" : ""} `
      }>
      {children}
  </section>;
}

Row.defaultProps = {
  fullscreen: false,
  className: ""
};

Row.propTypes = {
  fullscreen: PropTypes.bool.isRequired,
  childRef: PropTypes.any,
};

export default Row;
