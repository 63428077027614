import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import MoreList from "../../components/Lists/MoreList";
import Row from "../../layouts/Row";
import Image from "../../components/Media/Image";
import CartList from "../../components/Lists/CartList";
import Paragraph from "../../components/Texts/Paragraph";

import * as cartActions from "../../../modules/cart/actions";

import { useDispatch } from "react-redux";
import "./cartModal.less";

interface ICartModal{
    cart : any,
    closeCartModal: Function,
    number: number,
    socket: string
    updateCart: Function
}

const CartModal = (props : ICartModal) => {
    const { cart, closeCartModal, updateCart } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [foodList, setFoodList] : any = useState([]);
    const [recalculateCard, setRecalculateCard] : any = useState(0);
    const moreList = [{name: "Miso ramen", price: "42", img: "/list/food5.webp"}, {name: "Sopa de verduras", price: "20", img: "/list/food6.webp"}, {name: "Rollitos Crujientes", price: "60", img: "/list/food9.webp"}];

    const subtotal = () => {
        let totalPrice = 0;
        foodList.map((item: { price: number; }) => {
            totalPrice += Number(item.price);
        });
        return totalPrice;
    }

    const items = foodList.map((food : any, index : number) => <CartList key={index} border={index !== foodList.length-1 ? "border" : ""} updateCart={updateCart} cart={cart} food={food} recalculateCard={recalculateCard} setRecalculateCard={setRecalculateCard} />);
    const items2 = moreList.map((food : any, index : number) => <MoreList key={index} updateCart={updateCart} cart={cart} food={food} recalculateCard={recalculateCard} setRecalculateCard={setRecalculateCard} />);

    const sendDelivery = () => {
        if(cart.length > 0){
            const revenue = subtotal();
            let content = "";
            let total = 0;
            cart.map((food: { name: string; }) => {
                content = content + food.name;
                total = total + 1;
            });
            navigate("/payment");
            /*dispatch(cartAction.insertOrder(total, revenue, content, "ee6dzTUWvfQp8nFFtv", (response) => {
                navigate("/payment");
                //socket.emit('join', number+1);
            }));*/
        }

    }

    const removeAll = () => {
        dispatch(cartActions.removeAll((response : any) => {
            updateCart(response);
        }));
    }

    React.useEffect(() => {
        let emptyList : any = [];
        cart.map((food: { name: string; price: number; image: string; }) => {
            if(!emptyList.find((item: { name: string; }) => item.name === food.name)){
                const result = cart.filter((item: { name: string; }) => item.name === food.name);
                emptyList.push({name: food.name, qty: result.length.toString(), price: (result.length * food.price).toString(), image: food.image});
            }
        });
        setFoodList(emptyList);
    }, [cart, recalculateCard]);

    return <section className='cartOpacityLayout'>
        <Row className="cartModalBackground">
            <div className="cartModalTitleContainer">
                <div>
                    <Paragraph className="bold">{intl.formatMessage({id: 'project.header.cart.foodList.title'})}</Paragraph>
                    <div className="cartModalTitleContainerFlex">
                        <div className="cartListTrashContainer" onClick={() => removeAll()}><img src="https://demo.exylon.app/restaurante/assets/img/slider/trash.svg" /></div>
                        <Paragraph className="grey">{intl.formatMessage({id: 'project.header.cart.foodList.remove'})}</Paragraph>
                    </div>
                </div>
                <div onClick={() => closeCartModal()}><Image width="30px" alt="img-back" source={"decoration/back.svg"} /></div>
            </div>
            <section className="cartFoodListContainer">
                {foodList.length > 0 && items}
            </section>
            <section className="paymentCart">
                <p>{intl.formatMessage({id: 'project.header.cart.foodList.totalPrice'})} {subtotal()}€</p>
                <div className="moreDeliveryCard">
                    <p>{intl.formatMessage({id: 'project.header.cart.more.title'})}</p>
                    {foodList.length > 0 &&
                        <div className="moreDeliveryCardList">
                            {items2}
                        </div>
                    }
                </div>
                <div className="paymentCartSendButton" onClick={() => sendDelivery()}><Paragraph>{intl.formatMessage({id: 'project.header.cart.foodList.button'})}</Paragraph></div>
            </section>
        </Row>
    </section>
}

export default CartModal;
