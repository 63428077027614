import { useDispatch } from "react-redux";
import Image from "../Media/Image";

import * as cartActions from "../../../modules/cart/actions";

import "./moreList.less";

const MoreList = (props: any) => {
  const { cart, food, updateCart, recalculateCard, setRecalculateCard } = props;

  const dispatch = useDispatch();

  const addProduct = () => {
        dispatch(cartActions.insertProduct(cart, food.name, "", food.price, food.img, {extras: [], method: []}, (response: any) => {
            updateCart(response);
            setRecalculateCard(recalculateCard+1);
        }));
    }

  return <div className="moreListCard">
    <img src={"https://demo.exylon.app/restaurante/assets/img"+food.img} />
    <div className="moreListCardContent">
        <p className="moreListCardContentTitle">{food.name}</p>
        <div className="moreListCardContentPrice">
          <p>{food.price}€</p>
          <div onClick={() => addProduct()}>
            <Image width="20px" height="20px" alt="plus-food-list" source="decoration/shopping.png"/>
          </div>
        </div>
    </div>
  </div>
}

export default MoreList;
