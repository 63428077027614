import React, { useEffect, useRef, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './exylonIcon.less';
import exylon from './exylon.png';
import { getCall } from "./services/exylon.service";
import { IOpenaiChoice } from "./services/openai.interface";

const KEYS: string[] = [];

const ExylonIcon = () => {
  const [active, setActive] = useState<boolean>(false);
  const [conversation, setConversation] = useState<IOpenaiChoice[]>([]);
  const transcriptRef = useRef('');

  const speechRecognition = useSpeechRecognition();

  useEffect(() => {
    console.log('active', active);
    if (!active) { return; }

    const activateSpeech = (): boolean => {
      if (!speechRecognition.browserSupportsSpeechRecognition) {
        console.error('Browser failed');
        return false;
      }
      if (!speechRecognition.isMicrophoneAvailable) {
        console.error('Microphone failed');
        return false
      }

      SpeechRecognition.startListening({ continuous: true, language: 'es-ES' });
      speechRecognition.resetTranscript();
      return true;
    }

    if (!activateSpeech()) {
      setActive(false);
      return;
    }
    console.info('speech activated');

    let intervalLoop: { transcript: string, time: number } | undefined = undefined;
    let interval = setInterval((): void => {
      // const hasKey = KEYS.some(key => transcriptRef.current.toLowerCase().includes(key));
      console.log(transcriptRef.current)
      if (!!intervalLoop && transcriptRef.current !== '' && transcriptRef.current === intervalLoop.transcript && (Date.now() - intervalLoop.time) > 1100) {
        speechRecognition.resetTranscript();
        setActive(false);
        clearInterval(interval);
        intervalLoop = undefined;
        if (transcriptRef.current === '') { return; }
        callApi(transcriptRef.current);
      } else {
        intervalLoop = { transcript: transcriptRef.current, time: Date.now() + (intervalLoop === undefined ? 2000 : 0) };
      }
    }, 1200);
  }, [active]);

  useEffect(() => {
    transcriptRef.current = speechRecognition.transcript;
  }, [speechRecognition, speechRecognition.transcript])

  const clearTranscript = (transcript: string): string => {
    let key: string = '';
    let indexKey: number | undefined = undefined;
    KEYS.forEach(currentKey => {
      let indexCurrentKey = transcript.indexOf(currentKey);
      if (indexCurrentKey !== -1) {
        if (!indexKey || indexKey > indexCurrentKey) {
          key = currentKey;
          indexKey = indexCurrentKey;
        }
      }
    });
    return !indexKey ? transcript : transcript.split(key).slice(1).join(key);
  }

  const callApi = async (transcript: string) => {
    transcript = clearTranscript(transcript);
    setConversation([...conversation, { role: 'user', content: transcript }]);
    const choice = await getCall([...conversation, { role: 'user', content: transcript }]);
    const utterance = new SpeechSynthesisUtterance();
    const content = choice[0].content;
    setConversation([...conversation, { role: 'user', content: transcript }, { role: 'assistant', content }]);
    utterance.text = content;
    window.speechSynthesis.speak(utterance);
  }

  const handleClick = () => {
    console.log('click');
    setActive(!active);
  };

  return (
    <div className="exylon-container">
      <img src={exylon} alt="exylon" onClick={handleClick} className={`exylon-image ${active ? 'exylon-active' : ''}`} />
    </div>
  )
};

export default ExylonIcon;
