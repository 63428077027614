import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { calculateCart } from '../../../utils/restaurant/restaurantkey';

import Image from '../../components/Media/Image';
import Column from '../../layouts/Column';
import FoodCarousel from '../../components/Lists/FoodCarousel';
import Paragraph from '../../components/Texts/Paragraph';

import * as cartActions from '../../../modules/cart/actions';
import * as cartSelectors from '../../../modules/cart/selectors';

import AllergensDetails from './AllergensDetails';
import ExtrasModal from './ExtrasModal';
import Row from '../../layouts/Row';

import "./foodDetails.less";

interface IFood{
    idF: number
    name: string,
    caracteristic: string,
    price: string,
    image: string,
    gluten: string,
    egg: string,
    peanut: string,
    nut: string,
    mollusc: string,
    crustacean: string,
    dairy: string,
    sesame: string,
    lupin: string,
    description: string,
    qty: number,
    extras: string,
    method: string
}

interface FoodDetails{
    foodList: Array<IFood>
    foodSelected: number,
    changeFoodSelected: Function,
    closeModal: Function,
    changeHeaderCart: Function
}

const FoodDetails = (props : FoodDetails) => {
    const {foodList, foodSelected, changeFoodSelected, closeModal, changeHeaderCart} = props;
    const [updateCartContainer, setUpdateCartContainer] = useState(0);
    const [showExtrasModal, setShowExtrasModal] = useState({status: false, value: {extras: "", method: ""}});

    const cart = useSelector(cartSelectors.getCart);

    const [currentCart, setCurrentCart] = useState(calculateCart(cart, foodList[foodSelected].name));
    const dispatch = useDispatch();

    const addProduct = () => {
        if(foodList[foodSelected].extras !== "" && foodList[foodSelected].method !== ""){
            setShowExtrasModal({status: true, value: {extras: foodList[foodSelected].extras, method: foodList[foodSelected].method}})
        }else{
            dispatch(cartActions.insertProduct(cart, foodList[foodSelected].name, foodList[foodSelected].description, foodList[foodSelected].price, foodList[foodSelected].image, {extras: [], method: []}, () => {
                setUpdateCartContainer(updateCartContainer+1);
            }));
        }
    }

    const removeProduct = () => {
        dispatch(cartActions.removeProduct(foodList[foodSelected].name, cart, () => {
            setUpdateCartContainer(updateCartContainer+1);
        }));
    }

    const getActualCart = () => {
        setUpdateCartContainer(updateCartContainer+1);
    }

    React.useEffect(() => {
        setCurrentCart(calculateCart(cart, foodList[foodSelected].name));
        changeHeaderCart();
    }, [updateCartContainer]);

    return <div className='opacityLayout'>
        <div className="foodCloseModalLayout" onClick={() => closeModal()} />
        <Row className="foodDetailsContainer">
            <Column className="foodCarouselContainer">
                <FoodCarousel getActualCart={getActualCart} closeModal={closeModal} changeFoodSelected={changeFoodSelected} foodSelected={foodSelected} foodList={foodList.filter(food => food.image != "")} />
            </Column>
            <Column className="foodCartContainer">
                <div onClick={() => removeProduct()}><Image alt="less-button" className="lessButton" width="20px" source={"decoration/less.svg"}></Image></div>
                <span>{currentCart}</span>
                <div onClick={() => addProduct()}><Image alt="plus-button" className="plusButton"  width="20px" source={"decoration/plus.png"}></Image></div>
            </Column>
            <Column className="foodCardDetails">
                <Paragraph className="foodCardName">{foodList[foodSelected].name}</Paragraph>
                <Paragraph className="foodCardDescription">{foodList[foodSelected].description}</Paragraph>
                <AllergensDetails food={foodList[foodSelected]} />
            </Column>
            <Image alt="blue-decoration" source={"decoration/blue.webp"} className="blueDecoration"></Image>
            <span className="foodCardPrice">{foodList[foodSelected].price+"€"}</span>
        </Row>
        {showExtrasModal.status && <ExtrasModal content={showExtrasModal.value} cart={cart} food={foodList[foodSelected]} updateCartContainer={updateCartContainer} setUpdateCartContainer={setUpdateCartContainer} setShowExtrasModal={setShowExtrasModal} />}
    </div>
}

export default FoodDetails;
