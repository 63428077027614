import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import WaitScreen from '../../../design/pages/WaitScreen';

import Home from '../../../design/pages/Home';
import PaymentPage from '../../../design/pages/PaymentPage';

interface IBody{
  currentSecretKey: string,

  socket: string,
  number: number,
  updateHeaderContainer: number
}

const Body = (props : IBody) => {
  const { currentSecretKey, socket, number, updateHeaderContainer } = props;

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  const handleResize = () => {
    setWidthScreen(window.innerWidth);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <Routes>
    <Route path="/" element={<Home socket={socket} number={number} updateHeaderContainer={updateHeaderContainer} currentSecretKey={currentSecretKey} widthScreen={widthScreen} />} />
    <Route path="/tag" element={<Navigate to="/" />} />
    <Route path="/payment" element={<PaymentPage />} />
    <Route path="/wait-screen" element={<WaitScreen />} />
  </Routes>
};

export default Body;
