const restaurantkey : any = [
    {id: "restaurante", keypass: "ee6dzTUWvfQp8nFFtv"}
]

export const returnCurrentKey = (id : string) => {
    return (restaurantkey.find((key: { id: string; }) => key.id == id)).keypass;
}

export const calculateCart = (cart : any, name : string) => {
    let finalList = [];
    cart.map((product : any) => {
        if(product.name === name){
            finalList.push(product);
        }
    });
    return finalList.length;
}