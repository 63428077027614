import {combineReducers} from 'redux';
import * as actionTypes from './actionTypes';

const initialState = {
    cart: []
}

const allCart = (state = initialState.cart, action : any) => {
    switch (action.type) {
        case actionTypes.GET_CART:
            return action.cart;
        default:
            return state;
    }
}

const reducer = combineReducers({
    allCart
});

export default reducer;
