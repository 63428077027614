import messages from './messages';
import { getCookie } from '../utils/cookies/cookiesUtils';
export const initReactIntl = () => {
  let locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    'es';

  const localeMessages = getCookie("lang") != "" ? messages[getCookie("lang")] : messages['es'];

  locale = localeMessages === messages['es'] ? 'es' : locale;

  return {locale, messages: localeMessages};
};
