import React, { useRef, useState } from 'react';
import { setCookie } from '../../utils/cookies/cookiesUtils';
import "./homeSliderControls.less";
import Button from '../components/Button/Button';

interface IHomeSliderControls{
    activeIndex : number
    changeIndex : Function
    images : Array<String>
}

const HomeSliderControls = (props : IHomeSliderControls) => {
    const { activeIndex, changeIndex, images } = props;


   
    React.useEffect(() => {
        const buttons = document.querySelectorAll(
            ".homeSliderControls .button"
        );

        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener("click", () => handleClick(i));
        }
    });


    const handleClick = (index : number) => {
        const buttons = document.querySelectorAll(".homeSliderControls .button");
        const slider : any = document.querySelector(".homeSlider > div");


        for (let j = 0; j < buttons.length; j++) {
            buttons[j].classList.remove("active");
        }


        buttons[index].classList.add("active");
        slider.scrollLeft = index * slider.offsetWidth;
    };


    const action = () => {
        handleClick(activeIndex+1);
    }

    const setTutorial = (cname : string) => {
        setCookie("tutorial", cname, 365);
        window.location.reload();
    };

    return <>
        <div className="homeSliderControls" >
            {images.map((image, index) => (
                <Button className={index === activeIndex ? "active" : ""} key={index} />
            ))}
        </div>
        {activeIndex != 2
            ? <div className="homeSliderActionButton"><div onClick={() => action()}>Siguiente</div></div>
            : <div className="homeSliderActionButton"><div onClick={() => setTutorial("tuto")}>Comenzar</div></div>
        }
    </>;
};


export default HomeSliderControls;