import React, {lazy, useState} from 'react';

import PromotionsList from '../../components/Lists/PromotionsList';
import Row from '../../layouts/Row';
import Column from '../../layouts/Column';

const PrincipalSlider = () => {
    const [classType, setClassType] = useState("SliderPointsFirst");
    const classList = ["SliderPointsFirst","SliderPointSecond","SliderPointsThird"];
    const list = [0 , 1 , 2];
    const points: JSX.IntrinsicAttributes[] = list.map((item, index) => <div key={index} />);

    const changePoints = (number: number) => {
        setClassType(classList[number]);
    }

    //<PromotionsList changePoints={changePoints} list={[{type: "blue", title: "Arroz especiado", text: "Arroz con diversas especias, acompañado con verduras a la plancha.", img: "https://restaurante.crushthebyte.com/restaurante/assets/img/list/food1.png"}, {type: "purple", title: "Gambas a la plancha", text: "Gambas a la plancha con aceite de oliva, perejil y ajo. Acompañadas por una salsa especial teriyaki.", img: "https://restaurante.crushthebyte.com/restaurante/assets/img/list/food2.png"},{type: "green", title: "Rollitos crujientes", text: "Deliciosos y crujientes rollitos acompañados con espárragos verdes.", img: "https://restaurante.crushthebyte.com/restaurante/assets/img/list/food9.webp"}]} promotions={true} keypass={""}/>

    return <Row>
        <PromotionsList changePoints={changePoints} list={["https://demo.exylon.app/restaurante/assets/img/list/food1.png", "https://demo.exylon.app/restaurante/assets/img/list/food2.png", "https://demo.exylon.app/restaurante/assets/img/promotions/blue.png"]} promotions={true} keypass={""}/>
        <Column className={classType}>
            {points}
        </Column>
    </Row>
}

export default PrincipalSlider;
