import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getCookie } from '../../../utils/cookies/cookiesUtils';
import { returnCurrentKey } from '../../../utils/restaurant/restaurantkey';

import LanguageSelector from '../../../design/pages/LanguageSelector';
import TutorialSlideShow from '../../../design/pages/TutorialSlideShow';
import Tag from '../../../design/pages/Tag';

import Body from './Body';
import BodyModern from './BodyModern';
import Footer from './Footer';

import * as authService from "../../../api/services/authService";

import '../styles/global.less';

//const ENDPOINT = "http://127.0.0.1:4001";
//const socket = socketIOClient(ENDPOINT);
const socket = "";

const App = () => {

  const [permit, setPermit] = useState<boolean | null>(null);
  const currentSecretKey = returnCurrentKey("restaurante");
  const currentTemplate : string = "normal";

  const [updateHeaderContainer, setUpdateHeaderContainer] = useState(0);
  const [number, setNumber] = useState(0);

  const currentLanguage = getCookie("lang");
  const currentTutorial = getCookie("tutorial");

  const changeHeaderCart = () => {
    setUpdateHeaderContainer(updateHeaderContainer+1);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    const picc = url.searchParams.get('picc_data');
    const cmac = url.searchParams.get('cmac');

    authService.checkIfAuth((response : any) => {
        if(response.ok === 1){
          setPermit(true);
        }else{
          setPermit(false);
        }
    });

    /*if(!picc || !cmac){
      authService.checkIfAuth((response : any) => {
        if(response.ok === 1){
          setPermit(true);
        }else{
          setPermit(false);
        }
      });
    }else{
      authService.checkSession(picc, cmac, (response : any) => {
        if(response.ok === 1){
          setPermit(true);
        }else{
          setPermit(false);
        }
      });
    }*/
  }, []);


  const AppContent = () => {
    return <>
        {currentTemplate != "modern" ?
          <>
              {currentTutorial != "" ?
                <>
                  <Body socket={socket} number={number} updateHeaderContainer={updateHeaderContainer}  currentSecretKey={currentSecretKey} />
                  <Footer />
                </>
                : <TutorialSlideShow />
              }
          </>
        :
        <>
              {currentLanguage != "" ?
                  <>
                    {currentTutorial != "" ?
                      <>
                        <BodyModern socket={socket} number={number} updateHeaderContainer={updateHeaderContainer} changeHeaderCart={changeHeaderCart} currentSecretKey={currentSecretKey} />
                        <Footer />
                      </>
                    : <TutorialSlideShow />
                    }
                  </>
              : <LanguageSelector />
              }
        </>
        }
    </>
  }

  return <BrowserRouter>
    <Routes>
      {permit !== null &&
        <>
          {permit
            ? <Route path="/*" element={<AppContent />} />
            : <>
              <Route path="/tag" element={<Tag />} />
              <Route path="*" element={<Navigate to="/tag" />} />
            </>
          }
        </>
      }
    </Routes>
  </BrowserRouter>

};

export default App;
