import * as categoryService from '../../api/services/categoryService';
import * as actionTypes from './actionTypes';


const getCategoriesCompleted = (data : any) => ({
    type: actionTypes.GET_CATEGORIES,
    categories: data,
  });


export const getCategories : any = (keypass : string , onSuccess : Function) => (dispatch : any) => {
    categoryService.getCategories(keypass, (response : any) => {
      dispatch(getCategoriesCompleted(response));
      onSuccess(response);
    });
};