import React from "react";

import "./button.less";

interface IButton{
    children?: any
    className: string
}

const Button = (props : IButton) => {
    const { children, className } = props;
    
    return <a className={className ? `button ${className}` : "button"} >{children}</a>;
};

export default Button;
