import React from 'react';
import PropTypes from 'prop-types';

import './image.less';

interface IImage {
  source: string,
  alt?: string,
  width?: string,
  height?: string,
  children?: any,
  className?: string,
  classNameContainer?: string
}

const Image = (props : IImage) => {
  const {source, alt, width, height, children, className, classNameContainer} = props;
  const [size, setSize] = React.useState({width: width, height: height});

  const onImageLoad = ({ target: img } : any) => {
    if(width=='auto' && height=='auto'){
      const { offsetWidth, offsetHeight } = img;
      setSize({width: offsetWidth, height: offsetHeight});
    }
  };

  return <div className={classNameContainer}>
    <img
      className={className}
      onLoad={onImageLoad}
      alt={alt}
      src={"https://demo.exylon.app/restaurante/assets/img/"+source}
      width={size.width}
      height={size.height}
    />
    {children}
  </div>
}

Image.defaultProps = {
  height: 'auto',
  width: 'auto'
}

Image.propTypes = {
  source: PropTypes.string.isRequired,
  height: PropTypes.any,
  width: PropTypes.any
};

export default Image;
