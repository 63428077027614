import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import TagModal from "../blocks/tag/TagModal";

import * as authService from "../../api/services/authService";
import CodeModal from "../blocks/tag/CodeModal";

const Tag = () => {
  const location = useLocation();

  const restaurantList = [
    {id: "58343944", name: "Alberto"}
  ]

  const tableList = [
    {uid: "04AE58AA081690", restaurantId: "58343944", tableNumber: "10"}
  ]

  const [loading, setLoading] = useState(true);
  const [phoneToSend, setPhoneToSend] = useState("");
  const [auth, setAuth] = useState<any>({status: false, restaurant: "", table: ""});

  const sendSMS = (phone : string) => {
    authService.sms(phone, (response : any) => {
      if(response.ok === 1){
        setPhoneToSend(phone);
      }
    });
  }

  const sendAuth = (code : string) => {
    authService.auth(code, phoneToSend, auth.restaurant, auth.table, (response : any) => {
      if(response.ok === 1){
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);

    const picc = queryParams.get('picc_data');
    const cmac = queryParams.get('cmac');

    const table = tableList.find(table => table.uid === "04AE58AA081690");
    const restaurant = restaurantList.find(restaurant => restaurant.id === table?.restaurantId);
    setAuth({status: true, restaurant: restaurant?.name, table: table?.tableNumber, phone: ""});

    /*if(picc && cmac){
      authService.decodeNFC(picc, cmac, (response : any) => {
        if(response){
          const table = tableList.find(table => table.uid === "04AE58AA081690");
          const restaurant = restaurantList.find(restaurant => restaurant.id === table?.restaurantId);
          setAuth({status: true, restaurant: restaurant?.name, table: table?.tableNumber, phone: ""});
        }else{
          setAuth(false);
        }
      });
    }else{
      setAuth(false);
    }*/

    setLoading(false);
  }, []);

  return <>
    {!loading
      ? <>
        {auth.status
          ? <>
            {phoneToSend === ""
              ? <TagModal sendSMS={sendSMS} />
              : <CodeModal sendAuth={sendAuth} phoneToSend={phoneToSend} />
            }
          </>
          : <p>Lo sentimos, no tienes permisos para acceder a la web.</p>
        }
      </>
      : <></>
    }
  </>
}

export default Tag;
