import { useEffect, useState } from "react";

import "./tagModal.less";
import OTPInput from "./OtpInput";

interface OTPCredentialRequestOptions extends CredentialRequestOptions {
  otp: {
    transport: string[];
  };
}

interface ICodeModal{
  sendAuth: Function
  phoneToSend: string
}

const CodeModal = (props : ICodeModal) => {
  const { sendAuth, phoneToSend } = props;

  const [code, setCode] = useState<string>("");

  const handleOTPChange = (value: string) => {
    setCode(value);
  };

  return <div className="tag-modal">
    <img src="https://demo.exylon.app/restaurante/assets/img/nfc/recibe.png" />
    <h1>Código de verificación</h1>
    <p>Por favor, introduce el código enviado a <label>{phoneToSend}</label></p>
    <div className="tag-modal-button">
      <OTPInput length={6} onChangeOTP={handleOTPChange} />
      <button onClick={() => sendAuth(code)}>Enviar</button>
    </div>
  </div>
}

export default CodeModal;
