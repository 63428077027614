import {combineReducers} from 'redux';
import * as actionTypes from './actionTypes';


const initialStateType= {
    idT: null,
    type: null,
    number: null,
    idR: null
}

const initialState = {
    types: [],
    type: initialStateType
}

const allTypes = (state = initialState.types, action : any) => {
    switch (action.type) {
        case actionTypes.GET_TYPES:
            return action.types;
        default:
            return state;
    }
}

const reducer = combineReducers({
    allTypes
});

export default reducer;