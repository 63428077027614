import React, {useState} from 'react';
import * as cartSelectors from '../../../modules/cart/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as cartActions from "../../../modules/cart/actions";
import Image from '../../../design/components/Media/Image';

import CartModal from '../../../design/blocks/cart/CartModal';
import Paragraph from '../../../design/components/Texts/Paragraph';

import '../styles/header.less';

interface IHeader {
  updateHeaderContainer: number
  number: number,
  socket: any
}

const Header = (props : IHeader) =>{
  const { updateHeaderContainer, number, socket } = props;

  const dispatch = useDispatch();
  const {state} = useLocation();
  const cart = useSelector(cartSelectors.getCart);
  const [currentCart, setCurrentCart] = useState(cart);
  const [displayCartModal, setDisplayCartModal] = useState(false);

  React.useEffect(() => {
    setCurrentCart(cart);
  }, [updateHeaderContainer]);

  const closeCartModal = () => {
    setDisplayCartModal(false);
  }

  const updateCart = (cart : any) => {
    setCurrentCart(cart);
  }

  React.useEffect(() => {
    if(state != null){
        const { popup } = state;
        dispatch(cartActions.removeAllCart(() => {
          setCurrentCart([]);
        }));
    }
  }, []);

  return <header>
    <nav className='navmenu'>
      <p className='navRestauranteCounter'>12:07</p>
      <Paragraph className='navRestauranteTitle'>Alberto (Mesa 12)</Paragraph>
      <section onClick={() => setDisplayCartModal(true)}>
        <Image classNameContainer="cartIconHeaderContainer" className="cartIconHeader" width="100%" height="100%" alt="recipes-cart" source={"header/cart.svg"} >
          <div className="cartRedCounter">
              {currentCart.length}
          </div>
        </Image>
      </section>
      {displayCartModal && <CartModal socket={socket} cart={cart} number={number} closeCartModal={closeCartModal} updateCart={updateCart} />}
    </nav>
  </header>
}

export default Header;
