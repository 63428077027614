import Image from '../Media/Image';
import Paragraph from '../Texts/Paragraph';
import './promotionsList.less';
import React, { useRef } from 'react';

import { returnCurrentKey } from '../../../utils/restaurant/restaurantkey';
import {useDispatch, useSelector} from 'react-redux';
import * as categorySelectors from '../../../modules/categories/selectors';
import * as categoryActions from '../../../modules/categories/actions';

interface IPromotionsList{
    changePoints: Function,
    list: Array<any>,
    promotions: boolean,
    filterListByCategory?: Function,
    keypass: string
}

const PromotionsList = (props : IPromotionsList) => {
    const dispatch = useDispatch();
    const { changePoints , list , promotions, filterListByCategory, keypass } = props;
    const listInnerRef : any = useRef();

    const categoryList = useSelector(categorySelectors.getCategories);

    /*React.useEffect(() => {
        dispatch(categoryActions.getCategories(returnCurrentKey("restaurante")));
    }, []);*/

    const checkLimitScroll = (scrollLeft : number) => {
        const over0 = scrollLeft >= 0 && scrollLeft < 355;
        const over355 = scrollLeft >= 355 && scrollLeft < 555;
        const over555 = scrollLeft >= 555;

        switch(true) {
            case over0:
                return 0;
            case over355:
                return 1;
            case over555:
                return 2;
            default:
                return 0;
        }
    }

    const onScroll = () => {
        if (listInnerRef.current) {
          const { scrollLeft } = listInnerRef.current;
          changePoints(checkLimitScroll(scrollLeft));
        }
    };

    /*
    <div className={`${item.type}`} key={item.title} >
            <div className='promotionsListPromoContentContainer'>
                <Paragraph className='promotionsListPromoContentTitle'>{item.title}</Paragraph>
                <Paragraph className='promotionsListPromoContentText'>{item.text}</Paragraph>
            </div>
            <div className="promotionsListPromoImageContainer"><img src={item.img} alt="promo img" /></div>
        </div>
    */

    const items = list.map((item : string) =>
        <div key={item} className='promotionContainerCard'>
            <img src={item} alt="promo img" />
        </div>
    );

    const items2 = categoryList.map((item : any) =>
        <div className={`${item.colour}`} key={item.idC} onClick={() => filterListByCategory?.(item.idC, item.title)}>
            <Image alt="recipes-accordeon" source={item.image} classNameContainer={"image-container-circular"}/>
            <Paragraph className="food">{item.title}</Paragraph>
            <div className='foodArrow'>
                <img src="https://demo.exylon.app/restaurante/assets/img/decoration/arrow-down.svg" />
            </div>
        </div>
    );

    if(promotions){
        return <div onScroll={() => onScroll()} ref={listInnerRef} className='promotionContainer'>
            {items}
        </div>
    }

    return <div onScroll={() => onScroll()} ref={listInnerRef} className='foodContainer'>
        {categoryList && items2}
    </div>
}

export default PromotionsList;
