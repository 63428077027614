import React from "react";
import Paragraph from "../Texts/Paragraph";
import './categoryCard.less';

interface ICategoryCard{
    actualType: string,
    updateList: Function
}

const CategoryCard = (props : ICategoryCard) => {
    const { actualType, updateList } = props;

    //<div className="nextCategoryList" onClick={() => updateList()}/>

    return <div className="categoryCard" onClick={() => updateList()}>
        <div className="text"><Paragraph>{actualType}</Paragraph></div>
        <div className="categoryCardRightArrow"><img src="https://demo.exylon.app/restaurante/assets/img/slider/right_arrow.png" /></div>
    </div>
}

export default CategoryCard;
