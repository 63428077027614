import Cards from 'react-credit-cards';
import React, { useState } from 'react';
import Row from '../layouts/Row';
import Column from '../layouts/Column';
import "./paymentPage.less";
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { removeAllCart } from '../../modules/cart/actions';

const PaymentPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [state, setState] : any = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt : any) => {
        const { name, value } = evt.target;

        setState((prev : any) => ({ ...prev, [name]: value }));
    }

    const returnPopUp = () => {
        navigate(`/`, { state: { popup: true}});
    }

    return <Row>
        <div className='paymentBackContainer'>
            <div className='paymentBackImgContainer'><img src="https://demo.exylon.app/restaurante/assets/img/payments/back.png" /></div>
            <p className="paymentTitle">{intl.formatMessage({id: 'project.payment.title'})}</p>
        </div>
        <div className='paymentPayImgContainer'>
            <div className='paymentBackImgContainer'><img src="https://demo.exylon.app/restaurante/assets/img/payments/card.png" /></div>
            <p className="paymentTitle">PAGO</p>
        </div>
        <Row className='paymentSecondRowContainer'>
            <Cards
            number={state.number}
            expiry={state.expiry}
            cvc={state.cvc}
            name={state.name}
            focused={state.focus}
            />
            <div className='formPaymentBackground'>
                <div className='formPaymentCentered'>
                    <Row>
                        <Column className='formPaymentTypesContainer'>
                            <div><img src="https://demo.exylon.app/restaurante/assets/img/payments/master.png" /></div>
                            <div><img className='formPaymentPayImage' src="https://demo.exylon.app/restaurante/assets/img/payments/pay.png" /></div>
                            <div><img src="https://demo.exylon.app/restaurante/assets/img/payments/visa.png" /></div>
                            <div><img className='formPaymentNImage' src="https://demo.exylon.app/restaurante/assets/img/payments/n.png" /></div>
                        </Column>
                        <Column>
                            <div className='paymentPageNumberNameContainer'>
                                <input name="number" placeholder='Add card number...' onChange={handleInputChange}></input>
                                <input name="name" placeholder='Name...' onChange={handleInputChange}></input>
                            </div>
                            <div className='paymentPageExpiryCvcContainer'>
                                <input name="expiry" placeholder='MM/YY' onChange={handleInputChange}></input>
                                <input name="cvc" placeholder='CVC' onChange={handleInputChange}></input>
                            </div>
                            <div className='paymentSaveInfoCheckboxContainer'>
                                <input type="checkbox"></input>
                                <p className='paymentSaveInfoCheckboxText'>{intl.formatMessage({id: 'project.payment.checkbox.saveInfo'})}</p>
                            </div>
                            <p className='paymentSaveInfoCheckboxDetails'>Al guardar este método de pago la próxima vez que realices una compra se mostrará la opción de pago rápido. Recuerde que acepta los términos y condiciones al aceptar esta opción.</p>
                        </Column>
                    </Row>
                </div>
                <div className='paymentConfirmButton' onClick={() => returnPopUp()} >CONFIRMAR</div>
            </div>
        </Row>
    </Row>
}

export default PaymentPage;
